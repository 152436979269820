import React, { Suspense, lazy, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Box, Grid, Paper, CircularProgress } from '@mui/material'
import classnames from 'classnames'
import Header from 'features/UnauthHeader'
import WarningHeader from 'features/WarningHeader'
import Footer from 'features/Footer'

import useStyles from './styles'

const StartPage = lazy(() => import('../StartPage'))
const StartAfterDeprecation = lazy(() => import('../StartAfterDeprecation'))
const RestorePassword = lazy(() => import('../RestorePassword'))
const CheckProfile = lazy(() => import('pages/Profile/EditedProfile'))
const NoRegistration = lazy(() => import('../NoRegistration'))
// const Entrance = lazy(() => import('../Entrance'))

const LayoutPage = ({ checkProfile }: { checkProfile: boolean }) => {
  const { classes } = useStyles()

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    // navigate('/start')
  }, [])

  return (
    <Paper elevation={0} className={classnames(classes.paperRoot, classes.mainBackground)}>
      <WarningHeader/>
      {location.pathname !== '/' && <Header position={'static'} checkProfile={checkProfile}/>}
      {location.pathname !== '/start' && location.pathname !== '/install-app' && <Box height={40} mt={7}/>}
      <Box
        className={classnames({
          [classes.startContainer]: location.pathname === '/start',
          [classes.containerRoot]: location.pathname !== '/start',
          [classes.relative]: location.pathname === '/install-app',
        })}
      >
        <Grid container>
          <Suspense
            fallback={
              <div className="box-flex-center">
                <CircularProgress/>
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<Navigate to={"/start"}/>}/>
              <Route path="/start" element={<StartPage/>}/>
              {/*<Route path="/signup" element={<Signup/>}/>*/}
              <Route path="/restore-password" element={<RestorePassword/>}/>
              <Route path="/check-profile" element={<CheckProfile/>}/>
              <Route path="/install-app" element={<NoRegistration/>}/>
              <Route path="*" element={<Navigate to={"/start"}/>}/>
            </Routes>
          </Suspense>
        </Grid>
      </Box>
      {location.pathname !== '/' && <Footer/>}
    </Paper>
  )
}

export default LayoutPage
